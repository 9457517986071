import React from "react";

import CreateUser from "../Components/CreateUser";
import GamesPlayedChart from "../Components/GamesPlayedChart";
import { UserList } from "../Components/UserList";
import { WeeklyActiveUsersChart } from "../Components/stats/WeeklyActiveUsersChart";
import { Card } from "antd";
export const Admin = () => {
  return (
    <div>
      <UserList />

      <br />
      <CreateUser />
      <br />
      <Card>
        <GamesPlayedChart />
      </Card>
      <br />
      <Card>
        <WeeklyActiveUsersChart />
      </Card>
    </div>
  );
};
