import React from "react";
import { Card, Spin, Table } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import { useFetchData } from "../hooks/useFetchData";

export const Stats = () => {
  return (
    <div style={{ width: 800 }}>
      Stats for past 30 days:
      <br />
      <br />
      <HeadsetUsage />
      <br />
      <GameSessionVersionCounts />
      <br />
      <GamePlayRates />
      <br />
      <LauncherVersionCounts />
    </div>
  );
};

const HeadsetUsage = () => {
  const { data, isLoading } = useFetchData(`/api/admin/stats/headset_usage`);

  const columns = [
    {
      title: "Headset",
      dataIndex: "type",
      key: "headset",
    },
    {
      title: "count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "user_count",
      dataIndex: "user_count",
      key: "user_count",
    },
  ];
  if (isLoading) return <Spin />;

  return (
    <Card>
      Headset Usage
      <Table dataSource={data} columns={columns} />
    </Card>
  );
};

const GameSessionVersionCounts = () => {
  const { data, isLoading } = useFetchData(
    `/api/admin/stats/game_session_version_counts`
  );
  const columns = [
    {
      title: "version",
      dataIndex: "games_version",
      key: "version",
    },
    {
      title: "count",
      dataIndex: "count",

      key: "count",
    },
  ];
  if (isLoading) return <Spin />;

  return (
    <Card>
      Game sessions played by version
      <Table dataSource={data} columns={columns} />
    </Card>
  );
};

const GamePlayRates = () => {
  const { data, isLoading } = useFetchData(`/api/admin/stats/game_play_rates`);

  const columns = [
    {
      title: "game",
      dataIndex: "game",
      key: "game",
    },
    {
      title: "count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "player count",
      dataIndex: "player_count",
      key: "player_count",
    },
    {
      title: "",
      key: "stats link",
      render: (record: any) => (
        <a href={`/stats/game/${record.game}`}>
          <LineChartOutlined />
        </a>
      ),
    },
  ];

  if (isLoading) return <Spin />;
  return (
    <Card>
      Game play rates in last 30 days
      <Table dataSource={data} columns={columns} />
    </Card>
  );
};

const LauncherVersionCounts = () => {
  const { data, isLoading } = useFetchData(
    `/api/admin/stats/game_launcher_version_counts`
  );
  const columns = [
    {
      title: "version",
      dataIndex: "value",
      key: "version",
    },
    {
      title: "count",
      dataIndex: "count",
      key: "count",
    },
  ];
  if (isLoading) return <Spin />;

  return (
    <Card>
      launcher versions
      <Table dataSource={data} columns={columns} />
    </Card>
  );
};
